<template>
  <div class="login-page">
    <div class="poster"></div>
    <div class="form-warp">
      <div class="head">
        <div class="img-box" :style="{backgroundImage: `url(${avatar})`}">
<!--           <van-image class="img" :src="avatar"/>-->
        </div>
        <div class="text-box">
          <div class="nickname">好友<span>{{nick}}</span></div>邀请您一起畅聊嗨皮!
        </div>
      </div>
      <div class="form-content">

        <van-field class="form-item" type="tel" v-model="phone" clearable placeholder="请输入手机号" maxlength="13"
          @focus="noBomBox" @touchstart.native.stop="popKeyboard('phone')">
        </van-field>
        <van-field class="form-item" type="digit" v-model="code" clearable placeholder="请输入验证码"
          @touchstart.native.stop="popKeyboard" maxlength="4" @focus="noBomBox">
          <template #button>
            <van-button class="code-btn" size="small" round  :style="codeStyle" :disabled="s < 60" @click="getCode">{{
              codeText
            }}</van-button>
          </template>
        </van-field>
        <div class="btn">
          <van-image class="img" :src="require('../../assets/btn.png')" @click="login"/>
        </div>
      </div>
      <van-image class="list-img" :src="require('../../assets/list1.png')"/>
      <div class="copy-right">嗨皮直播 南宁多维互联科技有限公司 &copy; 2023 版权所有 <br/> 桂ICP备2022003788号</div>
    </div>

    <van-number-keyboard :show="phoneKeyboardShow" @blur="phoneKeyboardShow = false" @input="phoneInput"
      @delete="phoneDelete" />
    <van-number-keyboard :show="codeKeyboardShow" @blur="codeKeyboardShow = false" @input="codeInput"
      @delete="codeDelete" />
    <van-popup v-model="show" class="popup-cus-content" position="bottom" :style="{ height: '70%', padding: '20px' }"
      v-html="content" />
  </div>
</template>

<script>
import { Field, Icon, Button, Checkbox, Toast, Popup, NumberKeyboard, Image } from "vant";
import { isPhone } from "../../utils/regular";
import { getCode, login, info } from '../../api'
import { getUrlQuery } from '../../utils/common'

const COUNT = 60; // 倒计时长

export default {
  name: "loginPage",
  components: {
    [Field.name]: Field,
    [Icon.name]: Icon,
    [Button.name]: Button,
    [Checkbox.name]: Checkbox,
    [Popup.name]: Popup,
    [NumberKeyboard.name]: NumberKeyboard,
    [Image.name]: Image,
  },
  mounted() {
    let downUrl = localStorage.getItem("happy_down_url")
  	if (downUrl) {
		  this.$router.push({path: '/jump', query: {url: downUrl}})
    } else {
		  this.id = getUrlQuery("id");
		  this.userInfo()
    }
  },
  data() {
    return {
      phone: "",
      code: "",
      codeText: "获取验证码",
      phoneKeyboardShow: false,
      codeKeyboardShow: false,
      s: COUNT,
      show: false,
      content: '',
      id: 0,
      nick: '',
      avatar: '',
    };
  },
  computed: {
    codeStyle() {
      return this.s < 60 ? {backgroundColor: '#FFECF5', color: 'red'} : {backgroundColor: '#FFECF5', color: '#FE5AAA'}
    }
  },
  methods: {
	  userInfo() {
	  	info({code: this.id}).then(res => {
	  		this.nick = res.nick;
	  		this.avatar = res.avatar;
      })
	  },
    // 获取验证码
    async getCode() {
      // 加锁
      if (this.s < COUNT) return;
      if (!isPhone(this.phone.replaceAll(' ', ''))) {
        Toast("手机号格式有误");
        return;
      }
      try {
        await getCode({
          phone: this.phone.replaceAll(' ', '')
        })
        Toast("验证码发送成功，请注意查收");
        let timer = setInterval(() => {
          this.s--;
          this.codeText = `${this.s}s`;
          if (this.s <= 0) {
            this.s = COUNT;
            this.codeText = "获取验证码";
            clearInterval(timer);
          }
        }, 1000);
      } catch (error) {

      }
    },

    // 登录
    async login() {
      if (!this.phone) {
        Toast("请输入手机号码");
        return;
      }
      if (!this.code) {
        Toast("请输入验证码");
        return;
      }
      if (!isPhone(this.phone.replaceAll(' ', ''))) {
        Toast("手机号格式有误");
        return;
      }
	    await login({
		    phone: this.phone.replaceAll(' ', ''),
		    invite_code: this.id,
		    sms_code: this.code
	    }).then(res => {
		    localStorage.setItem("happy_down_url", res.url);
        this.$router.push({path: '/jump', query: {url: res.url}})
      });
    },

    phoneInput(value) {
      this.phone += value;
      if (this.phone.length === 3 || this.phone.length === 8) {
        this.phone += ' '
      }
    },
    phoneDelete() {
      let val = this.phone.slice(0, -1);
      if (val.slice(-1) === ' ') {
        val = val.slice(0, -1)
      }
      this.phone = val
    },
    codeInput(value) {
      this.code += value;
    },
    codeDelete() {
      this.code = this.code.slice(0, -1)
    },
    noBomBox(Event) {
      document.activeElement.blur();
    },
    popKeyboard(field) {
      if (field === "phone") {
        this.phoneKeyboardShow = true;
        this.codeKeyboardShow = false
      } else {
        this.phoneKeyboardShow = false;
        this.codeKeyboardShow = true
      }
    }
  }
};
</script>


<style>
.popup-cus-content {
  padding: 15px;
  overflow-y: scroll;
}

.van-popup p,
.van-popup span,
.van-popup i {
  font-size: 16px;
  color: #999999;
}
</style>

<style lang="scss">
.login-page {
  background-color: #F4C9F8;
  position: relative;

  .poster {
    height: 600px;
    background: url('../../assets/login_posrter1.png') no-repeat center;
    background-size: 100% auto;
  }

  .form-warp {
    margin: 0 10px;
    margin-top: -24px;
    border-radius: 10px;
    overflow: hidden;

    .head {
      height: 80px;
      background-color: #FEF4FF;
      display: flex;
      align-items: center;
      padding: 0 15px;

      .img-box {
        width: 42px;
        height: 42px;
        border-radius: 50%;
        margin-right: 12px;

        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;

        .img {
          width: 100%;
          height: 100%;
        }
      }

      .text-box {
        display: flex;
        align-items: center;
        font-size: 15px;
        font-weight: bold;

        .nickname {
          max-width: 150px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;

          span {
            color: red;
          }
        }
      }

    }

    .form-content {
      padding: 20px 15px;
      background-color: #ffffff;
      border-radius: 0 0 10px 10px;

      .form-item {
        border-radius: 24px;
        border: 1px solid #F3F3F3;
        height: 47px;
        display: flex;
        align-items: center;
        .van-cell__value {
          height: 47px;
          .van-field__body {
            height: 100%;
          }
        }

        &::after {
          border-bottom: 0;
        }

        &:nth-child(2) {
          // padding: 6px 6px 6px 20px;
          margin-top: 12px;
          padding-right: 5px;
        }

        .code-btn {
          width: 103px;
          height: 36px;
          border: unset;
          font-weight: bold;
        }
      }

      .btn {
        width: 270px;
        height: 45px;
        margin: 0 auto;
        margin-top: 50px;
      }
    }
  }
  .list-img {
    margin-top: 15px;
  }
  .copy-right {
    color: rgba(22, 18, 62, .5);
    text-align: center;
    margin: 20px 0 80px 0;
  }

}</style>
